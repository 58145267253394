import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getRouteToCarouselItem(
  item: CarouselItem,
  type?: LibraryType,
): string {
  if (item.type === "theme" && !type) {
    throw new Error(
      "The 'type' parameter is required when the item's type is 'theme'.",
    );
  }

  if ("url" in item) {
    return item.url;
  }

  switch (type) {
    case "application":
      return `/apps/${item.theme_id}`;
    case "overlay":
    case "widget":
      return `/library/items/${item.theme_id}`;
  }

  throw new Error("No URL or type provided for the carousel item.");
}

export function getRouteToItem(
  item?: LibraryItem | CarouselItem,
  type?: LibraryType,
) {
  if (!item) {
    return "/library";
  }

  if ("url" in item) {
    return item.url;
  }

  if ("theme_id" in item && type) {
    switch (type) {
      case "application":
        console.log(111);
        return `/apps/${item.theme_id}`;
      case "overlay":
        return `/library/items/${item.theme_id}`;
      case "widget":
        return `/library/items/${item.theme_id}`;
    }
  }

  if (item.type === "application") {
    return `/apps/${item.id}`;
  }

  if ("id" in item) {
    return `/library/items/${item.id}`;
  }

  if ("theme_id" in item) {
    return `/library/items/${item.theme_id}`;
  }

  return `/library`;

  // if (item.type === "overlay") {
  //   return `/library/overlays/${item.id}`;
  // } else if (item.type === "widget") {
  //   return `/library/widget-themes/${item.id}`;
  // } else if ("theme_id" in item) {
  //   return `/library/items/${item.theme_id}`;
  // }

  // return "/library";
}
